import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class PatternService {
    constructor(
        private _http: HttpClient
    ) { }

    // to get all the patterns
    getAllPatterns(fid) {
        return this._http.get(`${environment.portalUrl}/features/` + fid + `/patterns`)
    }

    getPatternsById(fid, ptid) {
        return this._http.get(`${environment.portalUrl}/features/` + fid + `/patterns/` + ptid)
    }

    // to add new pattern data
    addNewPattern(fid, name, status, keyParams, valueParams) {
        let data = {
            name: name,
            active: status,
            keys: {}
        };
        if (keyParams.length == valueParams.length) {
            keyParams.forEach((key, i) => data.keys[key] = valueParams[i]);
        }
        return this._http.post(`${environment.portalUrl}/features/` + fid + `/patterns/`, data);
    }

    // to update a pattern data by id
    updatePattern(fid, ptid, name, status, keyParams, valueParams) {
        let data = {
            name: name,
            active: status,
            keys: {}
        };
        if (keyParams.length == valueParams.length) {
            keyParams.forEach((key, i) => data.keys[key] = valueParams[i]);
        }
        return this._http.patch(`${environment.portalUrl}/features/` + fid + `/patterns/` + ptid, data);
    }

    // to update a pattern files by id
    updatePatternFiles(ptid, keyParams, fileParams) {
        let form = new FormData();
        if (keyParams.length == fileParams.length) {
            for (let i = 0; i < keyParams.length; i++) {
                form.append(keyParams[i], fileParams[i]);
            }
        }
        return this._http.post(`${environment.portalUrl}/patterns/` + ptid + `/imageData`, form);
    }

    // to delete a pattern by id
    deletePattern(fid, ptid) {
        return this._http.delete(`${environment.portalUrl}/features/` + fid + `/patterns/` + ptid);
    }

}