<div class="card mt-4">
    <h4 class="card-header">Home</h4>
    <div class="card-body">
        <p>You're logged in with Angular 9 & JWT!!</p>
        <p>Your role is: <strong>{{user.role}}</strong>.</p>
        <p>This page can be accessed by <u>all authenticated users</u>.</p>
        <p class="mb-1">Current user from secure api end point:</p>
        <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
        <ul *ngIf="userFromApi">
            <li>{{userFromApi.firstName}} {{userFromApi.lastName}}</li>
        </ul>
    </div>
    <div class="alert alert-primary" role="alert">
        A simple primary alert—check it out!
      </div>
      <div class="alert alert-danger" role="alert">
        A simple danger alert—check it out!
      </div>
</div>