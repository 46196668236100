import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ClientService {
    constructor(
        private _http: HttpClient
    ) { }

    // ---------------------
    // Client
    // ---------------------

    // to get all clients
    getAllClients() {
        return this._http.get(`${environment.portalUrl}/clients`)
    }

    getClientById(id) {
        return this._http.get(`${environment.portalUrl}/clients/` + id);
    }

    // to add a new client
    addNewClient(name, status, address) {
        let data = {
            'name': name,
            'active': status,
            'type': 'client/orbo',
            'address': address
        };
        return this._http.post(`${environment.portalUrl}/clients`, data);
    }

    // to update a client by id
    updateClient(id, name, status, address) {
        let data = {
            'name': name,
            'active': status,
            'type': 'client/orbo',
            'address': address
        };
        return this._http.patch(`${environment.portalUrl}/clients/` + id, data);
    }

    // to delete a client by id
    deleteClient(id) {
        return this._http.delete(`${environment.portalUrl}/clients/` + id)
    }

    // ---------------------
    // Client Products
    // ---------------------

    // to get all clients
    getAllSdks(clientId) {
        return this._http.get(`${environment.portalUrl}/clients/` + clientId + `/client-products/`)
    }

    getSdkById(clientId, clientProductId) {
        return this._http.get(`${environment.portalUrl}/clients/` + clientId + `/client-products/` + clientProductId);
    }

    // to add a new client
    addNewSdk(clientId, name, orboProductId) {
        let data = {
            'name': name,
            'orboProductId': orboProductId,
            // 'validity': validity
        }
        return this._http.post(`${environment.portalUrl}/clients/` + clientId + `/client-products`, data);
    }

    // // to update a client by id
    updateSdk(clientProductId, name, orboProductId) {
        let data = {
            'name': name,
            'orboProductId': orboProductId,
            // 'validity': validity
        }
        return this._http.patch(`${environment.portalUrl}/client-products/` + clientProductId, data);
    }

    // to delete a SDK by id
    deleteSdk(clientProductId) {
        return this._http.delete(`${environment.portalUrl}/client-products/` + clientProductId);
    }

    // upload SDK
    uploadSdk(clientProductId, file, version) {
        let form = new FormData();
        form.append('SDKFile', file);
        form.append('SDKVersion', version);
        return this._http.post(`${environment.portalUrl}/client-products/` + clientProductId + `/sdk/upload`, form);
    }

    // --------------------------
    // Client Products Profiles
    // --------------------------

    // to get all clients
    getAllProfiles(clientId) {
        return this._http.get(`${environment.portalUrl}/clients/` + clientId + `/profiles`)
    }

    getProfileById(clientId, clientProductId) {
        return this._http.get(`${environment.portalUrl}/clients/` + clientId + `/client-products/` + clientProductId);
    }

    // to add a new client
    addNewProfile(clientId, name) {
        let data = {
            'name': name,
            // 'validity': validity
        }
        return this._http.post(`${environment.portalUrl}/clients/` + clientId + `/profiles`, data);
    }

    // // to update a client by id
    updateProfile(clientProductId, name, orboProductId) {
        let data = {
            'name': name,
            'orboProductId': orboProductId,
            // 'validity': validity
        }
        return this._http.patch(`${environment.portalUrl}/client-products/` + clientProductId, data);
    }

    // to delete a SDK by id
    deleteProfile(clientProductId) {
        return this._http.delete(`${environment.portalUrl}/client-products/` + clientProductId);
    }

    // ---------------------
    // Client Products SKU
    // ---------------------

    // to get all clients
    getAllSkus(profileId) {
        return this._http.get(`${environment.portalUrl}/profiles/` + profileId + `/skus`)
    }

    getSkuById(profileId, clientProductId) {
        return this._http.get(`${environment.portalUrl}/profiles/` + profileId + `/skus/` + clientProductId);
    }

    // to add a new client
    addNewSku(profileId, name, desc, redirectUri, featureId, colorName, hexValue, rgbValue, status, intensity) {
        let data = {
            'clientSkuId': 'string',
            'name': name,
            'description': desc,
            'redirectURI': redirectUri,
            'tryons': [
                {
                    'featureId': featureId,
                    'color': {
                        'name': colorName,
                        'hexValue': hexValue,
                        'rgbValue': rgbValue,
                        'active': status
                    },
                    'intensity': intensity
                }
            ],
            'profileId': profileId
        }
        return this._http.post(`${environment.portalUrl}/profiles/` + profileId + `/skus`, data);
    }

    // // to update a client by id
    updateSku(clientProductId, name, orboProductId) {
        let data = {
            'name': name,
            'orboProductId': orboProductId,
            // 'validity': validity
        }
        return this._http.patch(`${environment.portalUrl}/client-products/` + clientProductId, data);
    }

    // to delete a SDK by id
    deleteSku(clientProductId) {
        return this._http.delete(`${environment.portalUrl}/client-products/` + clientProductId);
    }
}