import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import containers
import { DefaultLayoutComponent } from './containers';

import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import { Role } from './_models';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'products',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'features',
        loadChildren: () => import('./views/features/features.module').then(m => m.FeaturesModule)
      },
      {
        path: 'patterns',
        loadChildren: () => import('./views/patterns/patterns.module').then(m => m.PatternsModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./views/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./views/analytics/analytics.module').then(m => m.AnalyticsModule)
      }
    ]
  },
  // {
  //   path: '',
  //   component: HomeComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'login',
    component: LoginComponent
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
