﻿<!-- <div class="col-md-6 offset-md-3 mt-5">
    <div class="alert alert-info">
        <strong>Normal User</strong> - U: user P: user<br />
        <strong>Administrator</strong> - U: admin P: admin
    </div>
    <div class="card">
        <h4 class="card-header">Angular 9 Role Based Auth Example</h4>
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>


 -->





<div class="logo text-center mt-4 py-4"><a routerlink="/auth/login" href="/auth/login">
        <img src="assets/img/brand/logo.svg" width="200"></a></div>

<div class="d-flex ">
    <div class="col-md-6 offset-md-3">
        <div class="card py-0 ">
            <div class="card-body py-0 pl-lg-0">
                <div class="row">
                    <div class="col-lg-6 d-none d-lg-block border-right py-4 pr-5 bg-primary rounded-left">
                        <img src="assets/img/sample/login-otp-banner.webp" class="img-fluid mt-5  ml-5">
                    </div>
                    <div class="col-lg-6 py-5 px-lg-5">
                        <h5 class="card-title mb-4 text-center text-md-left">Login to your account</h5>
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" role="form">
                            <div class="form-group">
                                <label>Email address</label>
                                <input type="email" formControlName="email" class="form-control"
                                    placeholder="Enter email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.minLength || f.email.errors.isValidEmail">Invalid email
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <div class="input-group mb-3">
                                    <input [type]="passwordVisible ? 'text' : 'password'"
                                        class="form-control border-right-0" placeholder="Password"
                                        formControlName="password"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                    <div class="input-group-append pointer"
                                        (click)="passwordVisible = !passwordVisible">
                                        <span class="input-group-text bg-white">
                                            <img [src]="passwordVisible ? 'assets/img/sample/eye-off.svg' : 'assets/img/sample/eye.svg'"
                                                style="height: 18px; width: 18px;">
                                        </span>
                                    </div>
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                    </div>
                                </div>
                                <!-- <a routerLink="/auth/forgot-password" class="text-secondary d-inline-block mt-3">Forgot
							password?</a> -->
                            </div>
                            <div class="d-flex">
                                <button type="submit" class="btn btn-primary btn-block"
                                    [disabled]="isButtonDisabled">{{isButtonDisabled ? 'Logging in...' : 'Log
                                    In'}}</button>
                            </div>

                            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        </form>
                    </div>
                </div>
            </div>