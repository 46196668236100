import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User, Role } from './_models';
import { AuthenticationService } from './_services';
@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'orbo-portal';
  user: User;

  constructor(
    private _router: Router, private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.user.subscribe(x => this.user = x);
  }

  ngOnInit() {
    this._router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  get isAdmin() {
    return this.user && this.user.role === Role.Admin;
  }

  logout() {
    this._authenticationService.logout();
  }
}
