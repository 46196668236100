import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class FeatureService {
    constructor(
        private _http: HttpClient
    ) { }

    // to get all features
    getAllFeatures(pid) {
        return this._http.get(`${environment.portalUrl}/orbo-products/` + pid + `/features`)
    }

    getFeatureById(pid, fid) {
        return this._http.get(`${environment.portalUrl}/orbo-products/` + pid + `/features/` + fid);
    }

    // to add a new feature
    addNewFeature(pid, code, name, file, status) {
        let form = new FormData();
        form.append('featureCode', code);
        form.append('name', name);
        form.append('iconImage', file);
        form.append('active', status);
        return this._http.post(`${environment.portalUrl}/orbo-products/` + pid + `/features`, form);
    }

    // to update a feature by id
    updateFeature(pid, fid, code, name, file, status) {
        let form = new FormData();
        form.append('featureCode', code);
        form.append('name', name);
        form.append('iconImage', file);
        form.append('active', status);
        return this._http.patch(`${environment.portalUrl}/orbo-products/` + pid + `/features/` + fid, form);
    }

    // to delete a feature by id
    deleteFeature(pid, fid) {
        return this._http.delete(`${environment.portalUrl}/orbo-products/` + pid + `/features/` + fid)
    }

    // get all finishes of a feature
    getAllFinishes(pid, fid) {
        return this._http.get(`${environment.portalUrl}/orbo-products/` + pid + `/features/` + fid + `?filter={"fields": {"subTypes": true}}`)
    }

}