import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/products',
    icon: 'icon-speedometer'
  },
  {
    title: true,
    name: 'Products'
  },
  {
    name: 'All Products',
    url: '/products',
    icon: 'icon-layers',
  },
  {
    name: 'Features',
    url: '/features',
    icon: 'icon-puzzle',
  },
  {
    name: 'All Patterns',
    url: '/patterns',
    icon: 'icon-calculator',
    children: [
      {
        name: 'Lipstick',
        url: '/patterns/lipstick',
        icon: 'icon-user-female'
      },
      {
        name: 'Eyeshadow',
        url: '/patterns/eyeshadow',
        icon: 'cui-brush'
      },
      {
        name: 'Eyeliner',
        url: '/patterns/eyeliner',
        icon: 'cui-pencil'
      },
      {
        name: 'Eyelash',
        url: '/patterns/eyelash',
        icon: 'icon-eyeglass'
      },
      {
        name: 'Blush',
        url: '/patterns/blush',
        icon: 'icon-emotsmile'
      }
    ]
  },
  {
    name: 'All Clients',
    url: '/clients',
    icon: 'icon-people',
  },
  {
    name: 'Analytics',
    url: '/analytics',
    icon: 'icon-graph',
  },
  // {
  //   name: 'Try CoreUI PRO',
  //   url: 'http://coreui.io/pro/angular/',
  //   icon: 'icon-layers',
  //   variant: 'danger',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // }
];
