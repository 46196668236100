import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ProductsService {
    constructor(
        private _http: HttpClient
    ) { }

    // to get all products informations
    getAllProducts() {
        return this._http.get(`${environment.portalUrl}/orbo-products`);
    }

    // to get single product informations by id
    getProductById(id) {
        return this._http.get(`${environment.portalUrl}/orbo-products/` + id);
    }

    // to add a new product
    addNewProduct(name, description, status) {
        const data = {
            name: name,
            description: description,
            status: status
        }
        return this._http.post(`${environment.portalUrl}/orbo-products`, data);
    }

    // to delete a product by id
    deleteProduct(id) {
        return this._http.delete(`${environment.portalUrl}/orbo-products/` + id);
    }

    // to update a product by id
    updateProduct(id, name, description, status) {
        const data = {
            name: name,
            description: description,
            status: status
        }
        return this._http.patch(`${environment.portalUrl}/orbo-products/` + id, data);
    }
}